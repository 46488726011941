import { ScreenSize, SiteLocales } from "./types";

export const DEFAULT_PAGE_SIZE: number = 5;

export const SCREEN_SIZE: ScreenSize = {
  LG: 'lg',
  MD: 'md',
  SM: 'sm',
  XS: 'xs'
}

export const LOCALES: SiteLocales = {
  RU: 'ru',
  EN: 'en',
  KZ: 'kk'
}

export const DOMAINS: SiteLocales = {
  RU: 'ru',
  EN: 'com',
  KZ: 'kz'
}

export const ROUTES = {
  MAIN: '/',
  POLICY: '/policy',
  CONTACTS: '/contacts',
  PORTFOLIO: '/portfolio',
  SERVICES: '/services'
}