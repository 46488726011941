import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/home-page/home-page.component').then(
        (m) => m.HomePageComponent
      ),
  },
  {
    path: 'policy',
    loadComponent: () =>
      import('./components/policy/policy.component').then(
        (m) => m.PolicyComponent
      ),
    pathMatch: 'full',
  },
  {
    path: 'contacts',
    loadComponent: () =>
      import('./components/contact-us/contact-us.component').then(
        (m) => m.ContactUsComponent
      ),
    pathMatch: 'full',
  },
  {
    path: 'portfolio',
    loadComponent: () =>
      import('./components/portfolio/portfolio.component').then(
        (m) => m.PortfolioComponent
      ),
    pathMatch: 'full',
  },
  {
    path: 'portfolio/:directionUrl',
    loadComponent: () =>
      import('./components/direction/direction.component').then(
        (m) => m.DirectionComponent
      ),
    pathMatch: 'full',
  },
  {
    path: 'portfolio/:directionUrl/:projectName',
    loadComponent: () =>
      import('./components/project-page/project-page.component').then(
        (m) => m.ProjectPageComponent
      ),
    pathMatch: 'full',
  },
  {
    path: 'services',
    loadComponent: () =>
      import('./components/services-display/services-display.component').then(
        (m) => m.ServicesDisplayComponent
      ),
    pathMatch: 'full',
  },
  {
    path: 'services/:serviceName',
    loadComponent: () =>
      import('./components/service-detailed/service-detailed.component').then(
        (m) => m.ServiceDetailedComponent
      ),
    pathMatch: 'full',
  },
  {
    path: '**',
    loadComponent: () =>
      import('./components/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
  },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
