<header class="header" id="head1">
  <div class="container_header">
    @if (sandwFlag) {
      <div class="burger_menu">
        @for (title of titles; track title) {
          <span
            class="item_burger"
            (click)="clickOnMenu(title.link)"
            >{{ title.text }}</span
            >
          }
          <div class="btn-container">
            @for (locale of locales; track locale) {
              <span>
                <a href="{{ locale.url }}">
                  <img
                    style="width: 36px; margin-left: 10px; margin-right: 10px"
                    loading="lazy"
                    src="{{ locale.img }}"
                    alt="{{ locale.name }}"
                    />
                </a>
              </span>
            }
          </div>
          <span class="item_burger" (click)="openContactUs()"
            ><button class="contacts">{{ contact }}</button></span
            >
          </div>
        }

        <div class="header_body">
          <nav class="nav_item">
            @if (!sandwFlag) {
              <img
                (click)="toRoot()"
                class="header_img"
                src="{{ imgLogo }}"
                alt="Logo-E2E4"
                />
            }
            <div class="menu_links">
              @for (title of titles; track title) {
                <span
                  class="header_link"
                  title="{{ title.text }}"
                  >
                  <span
                    class="underline"
                    (click)="clickOnMenu(title.link)"
                    title=""
                    >{{ title.text }}</span
                    >
                  </span>
                }
              </div>
            </nav>

            <div class="btn_w_langs">
              @if (sandwFlag) {
                <span
                  (click)="sandwFlag = !sandwFlag"
                  class="hide_Menu"
                  >
                  <img
                    loading="lazy"
                    decoding="async"
                    src="/assets/images/Union.webp"
                    alt="Union"
                    width="24px"
                    height="24px"
                    />
                </span>
              }
              <span
                class="sandw"
                [ngStyle]="{ display: sandwFlag ? 'none' : '' }"
                (click)="sandw()"
                alt
                >
                <img
                  loading="lazy"
                  decoding="async"
                  src="/assets/images/Sandw_menu.webp"
                  alt="Sandw_menu"
                  width="32px"
                  height="24px"
                  />
              </span>
              <div class="lang-container">
                @for (locale of locales; track locale) {
                  <span class="lang">
                    <a href="{{ locale.url }}">
                      <img
                        class="flag-img"
                        src="{{ locale.img }}"
                        alt="{{ locale.name }}"
                        />
                    </a>
                  </span>
                }

                <button class="contact_us" type="button" (click)="openContactUs()">
                  {{ contact }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
