<footer class="container">
  <p class="copyright">{{ footerText }}</p>
  <p class="icons">
    @for (item of footerItems; track $index) {
      <a class="link" target="_blank" rel="noopener" href="{{ item.link }}">
        <img
          loading="lazy"
          decoding="async"
          [src]="item.image"
          [width]="item.width"
          [height]="item.height"
          alt="logo"
        />
      </a>
    }
  </p>
</footer>
