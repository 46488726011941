import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { StateService } from '../../../services/state-service.service';
import settings from '../../../../settings';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [],
})
export class FooterComponent implements OnInit, OnDestroy {
  public footerText: string = '';
  public footerItems: any[] = [];

  private _url: string = settings.apiUrl;
  private _subscriptions: Array<Subscription> = [];

  constructor(private _stateService: StateService) {}

  // todo: add types
  ngOnInit(): void {
    this._subscriptions.push(
      this._stateService.footerItems.subscribe((footer) => {
        this.footerText = footer?.Footer_text;
        footer?.footer_Link.forEach((item) => {
          if (item?.link && item?.image?.data?.attributes) {
            this.footerItems.push({
              link: item.link,
              image: this._url + item.image.data.attributes.url,
              width: item.image.data.attributes.width,
              height: item.image.data.attributes.height,
            });
          }
        });
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
